import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import actions from "../redux/actions";
import "./Bubble.scss";
import defaultLogo from './../assets/default_logo.png';
import taIcon from './../assets/Group.svg';


const Bubble = props => {

    const [visible, setVisible] = useState(true);

    useEffect(() => {
      setTimeout(() => {
        props.hideMessage()
      }, 10000);
    }, []);
    
    const openChat = () => {
        props.visibleChat()
        props.hideMessage()
    };

    if(props.config.showBubble === 1)
        return (  
            <div className={`ta-bubble-container ${props.config.actionButtons.appointment.enabled == 0 ? 'left' : 'left'} ${!props.helloMessage.visible ? 'hidden' : ''}`}>
                <div className={`close-btn`} onClick={props.hideMessage}>
                    <svg width="12" height="12" version="1.1"
                        xmlns="http://www.w3.org/2000/svg">
                        <line x1="1" y1="11" 
                            x2="11" y2="1" 
                            stroke="#9e9e9e" 
                            strokeWidth="2"/>
                        <line x1="1" y1="1" 
                            x2="11" y2="11" 
                            stroke="#9e9e9e" 
                            strokeWidth="2"/>
                    </svg>
                </div>
                <div onClick={openChat} className={`ta-box`}>
                    <div className="logo">
                        <img className="img1" src={props.config.bubbleLogo || defaultLogo} />
                        <img className="img2" src="https://app.trustanalytica.com/static/media/Group.741c0662.svg" />         
                    </div>
                    <div className="message">
                        {props.config.initialMessage}
                    </div>
                </div>
            </div>
        );
    return <React.Fragment></React.Fragment>;
}

const mapStateToProps = (state) => {
    return {
        chat: state.chat,
        helloMessage: state.helloMessage,
        config: state.config
    }
}

const mapDispatchToProps = {
    hideMessage: actions.hideMessage,
    visibleChat: actions.visibleChat,
    hideChat: actions.hideChat,
}

export default connect(mapStateToProps, mapDispatchToProps)(Bubble)