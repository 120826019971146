import types from "./types"

/**
 * Visible chat.
 * @returns {{visible: boolean, type: *}}
 */
const visibleChat = () => ({
    type: types.CHAT_VISIBLE,
    visible: true
})

/**
 * Hide chat.
 * @returns {{visible: boolean, type: *}}
 */
const hideChat = () => ({
    type: types.CHAT_VISIBLE,
    visible: false
})

/**
 * Visible hello message
 * @returns {{visible: boolean, type: *}}
 */
const visibleMessage = () => ({
    type: types.HELLO_MESSAGE_VISIBLE,
    visible: true
})

/**
 * Hide hello message.
 * @returns {{visible: boolean, type: *}}
 */
const hideMessage = () => ({
    type: types.HELLO_MESSAGE_VISIBLE,
    visible: false
})

/**
 * Set data of form.
 * @param form
 * @returns {{form: *, type: *}}
 */
const setForm = form => ({
   type: types.SET_FORM,
   form: form
})

/**
 * Set config.
 * @param config
 * @returns {{type: *, config: *}}
 */
const setConfig = config => ({
    type: types.SET_CONFIG,
    config
})

export default {
    visibleChat,
    hideChat,
    visibleMessage,
    hideMessage,
    setConfig,
    setForm
}