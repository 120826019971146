import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import Form from "./../components/Form";
import ChatButton from "./../components/ChatButton";
import PropTypes from "prop-types";
import actions from "../redux/actions";
import {getConfig} from "../api/Config";

//const ws = new WebSocket(process.env.REACT_APP_SOCKET_API_BASE);
const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

const Chat = props => {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [smallDevice, setSmallDevice] = useState(false);
    const [isChatActive, setChatActive] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');
    const [isMessageSent, setMessageSent] = useState(false);
    const [lastMessage, setLastMessage] = useState(null);
    const chatContainerRef = useRef();

    useEffect(() => {
        getConfig(props.uuid).then(_config => props.setConfig(_config))
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        setSmallDevice(windowDimensions.width < 415 || windowDimensions.height < 415);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (() => {
        if (props.config.error !== false) return <></>;
        const style = {
            height: `${windowDimensions.height}px`,
            width: `${windowDimensions.width}px`
        }

        let bgColor = {};
        let iconColor = "white";

        if (typeof props.config !== "undefined") {

            bgColor = {
                backgroundColor: props.config.primaryColor
            }

            iconColor = props.config.textColor;
        }

        // if (props.chat.visible) {
            return (
                <>
                    <div className={`ta-widget-chat ta-animate-fast ta-fade-in ${props.chat.visible ? '' : 'd-none'}`}>
                        <div className="ta-widget-chat-header" style={bgColor}>
                            <svg width="24" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                    clipRule="evenodd" d="M3.553 10.145c0-4.274 3.967-7.934 9.118-7.934s9.119 3.66 9.119 7.934-3.968 7.934-9.119 7.934a10.12 10.12 0 01-4.572-1.07.948.948 0 00-.755-.043l-4.236 1.557 1.682-3.562a.947.947 0 00-.063-.92 7.108 7.108 0 01-1.174-3.896zM12.67.315c-5.967 0-11.013 4.293-11.013 9.83 0 1.613.435 3.132 1.2 4.469L.407 19.806A.947.947 0 001.59 21.1l6.03-2.218a12.058 12.058 0 005.051 1.092c5.968 0 11.013-4.293 11.013-9.83 0-5.536-5.045-9.828-11.013-9.828zM8.842 11.369a1.263 1.263 0 100-2.526 1.263 1.263 0 000 2.526zm3.79 0a1.263 1.263 0 100-2.526 1.263 1.263 0 000 2.526zm5.052-1.263a1.263 1.263 0 11-2.526 0 1.263 1.263 0 012.526 0z"
                                    fill={iconColor}/>
                            </svg>
                            <div className="ta-widget-chat-header-title" style={{color: props.config.textColor}}>
                                {props.config.title ?? "We will Text You"}
                            </div>
                            <div className="ta-widget-chat-close"  onClick={props.hideChat}>
                                <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M.271 1.581a.926.926 0 011.31-1.31L9.73 8.42a.926.926 0 01-1.31 1.31L.27 1.58z"
                                        fill="#fff"/>
                                    <path
                                        d="M8.419.271a.926.926 0 111.31 1.31L1.58 9.73a.926.926 0 01-1.31-1.31L8.42.27z"
                                        fill="#fff"/>
                                </svg>
                            </div>
                        </div>
                        <form ref={chatContainerRef} className="ta-widget-chat-form">
                            <div className="ta-widget-chat-form-info">
                                Enter your info and we will text you back asap!
                            </div>

                            <Form
                                uuid={props.uuid}
                                primaryColor={props.config.primaryColor}
                                textColor={props.config.textColor}
                                visible={!isMessageSent}
                                onError={error => {
                                    setErrorMessage(error);
                                }}
                                onSend={message => {
                                    setLastMessage(message);
                                    setMessageSent(true);
                                }}
                                onChangeMessage={() => {
                                    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
                                }}
                                lastMessage={lastMessage}
                                />

                        </form>
                        <div className="ta-widget-chat-powered">
                            Powered by &nbsp;
                            <a href="https://trustanalytica.com/">
                                Trust<span>Analytica</span>
                            </a>

                        </div>
                    </div>
                    <ChatButton />
                </>
            )
        // } else {
        //     return null;
        // }
    })();

}


PropTypes.App = {
    uuid: PropTypes.string.isRequired
}

const mapStateToProps = state => {
    return {
        chat: state.chat,
        helloMessage: state.helloMessage,
        config: state.config
    }
}

const mapDispatchToProps = {
    visibleChat: actions.visibleChat,
    hideChat: actions.hideChat,
    hideMessage: actions.hideMessage,
    visibleMessage: actions.visibleMessage,
    setConfig: actions.setConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(Chat);