import {applyMiddleware, compose, createStore} from "redux";
import reducers from "./reducers";

const initState = {};

const store = createStore(
    reducers,
    initState,
    compose(
        applyMiddleware(),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
    )
)

export default store;