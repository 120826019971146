import React, {useEffect, useState, useRef} from "react";
import PropTypes from 'prop-types';
import actions from "../redux/actions";
import {connect} from 'react-redux';
import axios from "axios";
import "./Form.scss"
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import { toast } from 'react-toastify';

axios.defaults.baseURL = process.env.REACT_APP_SOCKET_API_BASE;

const styleError = {
    backgroundColor: "red"
}

const ValidIcon = () => {
    return (
        <svg className="ta-widget-chat-form-wrap-input-check" width="10" height="10"
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 10c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"
                  fill="#00CC7B"/>
            <path d="M3.125 5l1.25 1.25 2.5-2.5" stroke="#fff" strokeWidth="1.5"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const Form = props => {
    const textareaRef = useRef();

    const [chars, setChars] = useState({
        count: 0,
        charCode: 0,
        charactersLeft: 120
    });

    const [valid, setValid] = useState({
        name: false,
        phone: false,
        message: false
    });
    const [showReply, setShowReply] = useState(false);

    const [validForm, setValidForm] = useState(false);
    const [attemptToSend, setAttemptToSend] = useState(false);
    const [isDisabled, setDisabled] = useState(false);
    const [fields, setFields] = useState({
        name: null,
        phone: null,
        message: ''
    });
    const [showMask, setShowMask] = useState(false);

    useEffect(() => {
        if (valid.name && valid.phone && valid.message) {
            setValidForm(true);
        } else {
            setValidForm(false);
        }

    }, [valid]);

    useEffect(() => {
        validate();
    }, []);

    useEffect(() => {
        if(props.visible){
            setShowReply(false);
        }else{
            setTimeout(() => {
                setShowReply(true);
            }, 500);
        }
    }, [props.visible]);

    /**
     * Send message.
     * @param e
     */
    const sendMessage = e => {

        if (validForm) {
            setValidForm(false);
            console.log('Valid');
            axios.post('/webhook/widget/send', {
                name: props.form.name.trim(),
                phone: `+${props.form.phone.replace(/[\W]+/g, "")}`,
                phoneCode: props.form.phoneCode,
                phoneIso2: props.form.phoneIso2,
                message: props.form.message.trim(),
                uuid: props.uuid,
            }, {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                }
            }).then((res) => {
                if (res.status === 200) {
                    setValidForm(true);
                    if (res.data && res.data.error) {
                        toast.error(res.data.error, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (typeof props.onError !== "undefined") {
                            props.onError(res.data.error);
                        }
                    } else {
                        if (typeof props.onSend !== "undefined") {
                            props.onSend(fields.message);
                        }
                    }
                }
            });
        }

        setAttemptToSend(true);

        // console.log('validForm', validForm);
    }

    const changeInput = e => {
        let inputName = e.target.name;
        let inputValue = e.target.value;
        switch (inputName) {
            case 'name':
                setFields({
                    ...props.form,
                    [inputName]: inputValue
                });


                const trimInputValue = inputValue.trim();
                const reg = /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\']{2,}(?: [a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\']+){0,2}$/;
                if (reg.exec(trimInputValue)) {
                    setValid({
                        ...valid,
                        name: inputValue.length >= 2
                    });
                } else {
                    setValid({
                        ...valid,
                        name: false
                    });
                }
                props.setForm({
                    ...props.form,
                    [inputName]: inputValue
                });
                break;
            case 'phone':
                setFields({
                    ...props.form,
                    [inputName]: inputValue
                });

                setValid({
                    ...valid,
                    phone: inputValue.replace(/[\W\_]/g, "").length === 11
                });

                props.setForm({
                    ...props.form,
                    [inputName]: inputValue
                });
                break;
            case 'message':

                let text = e.target.value.length > 120 ? e.target.value.substr(0, 120) : e.target.value;

                if (chars.count < chars.charactersLeft || chars.charCode === 8) {
                    setFields({
                        ...props.form,
                        message: text
                    });

                    setValid({
                        ...valid,
                        message: e.target.value.length >= 5
                    });

                    setChars({
                        ...chars,
                        count: text.length
                    });

                    props.setForm({
                        ...props.form,
                        message: text
                    });
                }

                textareaRef.current.style.height = '28px';
                textareaRef.current.style.height =  `${textareaRef.current.scrollHeight}px`;

                props.onChangeMessage();
                break;
        }
    }

    const keyPress = e => {
        if (e.keyCode === 8 && chars.count === chars.charactersLeft) {
            const msg = fields.message.substr(0, fields.message.length - 1);
            setFields({
                ...fields,
                message: msg
            });

            setChars({
                ...chars,
                charCode: e.keyCode,
                count: e.target.value.length
            });
        }
    }

    const validate = () => {
        console.log('props.form', props.form);
        let validate = {
            ...valid
        }

        for (const prop in props.form) {

            if (props.form[prop]) {
                switch (prop) {
                    case 'name':
                        const trimInputValue = props.form[prop].trim();
                        const reg = /^[a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\']{2,}(?: [a-zA-ZàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\']+){0,2}$/;
                        if (reg.exec(trimInputValue)) {
                            validate = {
                                ...validate,
                                name: props.form[prop].length >= 2
                            }
                        } else {
                            validate = {
                                ...validate,
                                name: false
                            }
                        }
                        break;
                    case 'phone':
                        validate = {
                            ...validate,
                            phone: props.form[prop].replace(/[\W\_]/g, "").length === 11
                        }
                        break;
                    case 'message':

                        validate = {
                            ...validate,
                            message: props.form[prop].length >= 5
                        }
                        break;
                }
            }
        }
        setValid(validate);
    }

    let submitButtonStyles = {},
        bgColor = {},
        iconColor = "#4285F4";

    if (typeof props.config.primaryColor !== "undefined" && props.config.primaryColor !== '') {
        submitButtonStyles['backgroundColor'] = props.config.primaryColor;
        bgColor = {
            backgroundColor: props.config.primaryColor
        }
        iconColor = props.config.primaryColor;
    }

    if (typeof props.config.textColor !== "undefined" && props.config.textColor !== '') {
        submitButtonStyles['color'] = props.config.textColor;
    }

    const onNumberChange = (isValid, value, countryData, fullNumber) => {
        // console.log(isValid, value, countryData, fullNumber);
        setValid({
            ...valid,
            phone: value.length >= 8 && value.length <= 15
        });
        setFields({
            ...props.form,
            "phone": fullNumber
        });
        let phone = value.charAt(0) === '0' ? value.substring(1) : value;
        props.setForm({
            ...props.form,
            phone: "+"+countryData.dialCode+phone.replace("+"+countryData.dialCode, ""),
            phoneCode: countryData.dialCode,
            phoneIso2: countryData.iso2
        });
    }

    const onFlagChange = (phone, countryData) => {
        props.setForm({
            ...props.form,
            phone: "+"+countryData.dialCode+phone.replace("+"+countryData.dialCode, ""),
            phoneCode: countryData.dialCode,
            phoneIso2: countryData.iso2
        });
    }

    // Restricts input for the given textbox to the given inputFilter function.
    const setInputFilter = (textbox, inputFilter) => {
        ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
            textbox.addEventListener(event, function() {
                if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
                } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                } else {
                this.value = "";
                }
            });
        });
    }

    useEffect(() => {
        // console.log(document.querySelectorAll('.ta-widget-chat-form-fon-inputs [type="tel"]'));
        // return false;
        if(document.querySelectorAll('.ta-widget-chat-form-fon-inputs [type="tel"]')[0] === undefined) return;
        setInputFilter(document.querySelectorAll('.ta-widget-chat-form-fon-inputs [type="tel"]')[0], function(value) {
            return /^\+?\d*$/.test(value);
        });
    }, []);

    return (
        <>
            {props.visible &&
            <div className="ta-widget-chat-form-fon-inputs">
                <div className="ta-widget-chat-form-wrap-input">
                    <input id="Name"
                           name="name"
                           type="text"
                           required
                           tabIndex={1}
                           onChange={changeInput}
                           value={props.form.name ? props.form.name : ''}
                    />
                    <label htmlFor="Name">Name</label>
                    <div className="input-bar" style={fields.name && !valid.name ? styleError : {}}></div>
                    {valid.name && <ValidIcon/>}
                </div>
                <div className="ta-widget-chat-form-wrap-input">
                    <IntlTelInput
                        preferredCountries={[]}
                        defaultCountry="us"
                        onlyCountries={['us', 'ca', 'au']}
                        placeholder=""
                        onSelectFlag={onFlagChange}
                        onPhoneNumberChange={onNumberChange}
                    />
                    {/* 
                    <NumberFormat type={"tel"}
                                  format="+1 (###) ###-####"
                                  mask="_"
                                  id="mobile"
                                  name="phone"
                                  required
                                  onChange={changeInput}
                                  allowEmptyFormatting={showMask}
                                  onFocus={() => setShowMask(true)}
                                  onBlur={() => setShowMask(false)}
                                  tabIndex={2}
                                  value={props.form.phone ? props.form.phone : ''}
                    />
                    <label htmlFor="mobile">Mobile number</label>
                    */}
                    <div className="input-bar" style={attemptToSend && !valid.phone ? styleError : {}}></div>
                    {valid.phone && <ValidIcon/>}
                </div>
                <div className="ta-widget-chat-form-wrap-input">
                    <textarea id="message"
                              name="message"
                              required
                              tabIndex={3}
                              onChange={changeInput}
                              onKeyUp={keyPress}
                              value={props.form.message ? props.form.message : ''}
                              ref={textareaRef}
                    ></textarea>
                    <label htmlFor="message">Message...</label>
                    <div className="input-bar" style={attemptToSend && !valid.message ? styleError : {}}></div>
                </div>
            </div>
            }


            {!props.visible &&
            <>
                <div className="ta-widget-chat-form-delivered" style={submitButtonStyles}>
                    {props.lastMessage}
                    <div className="ta-widget-chat-form-delivered-info">
                        <svg width="8" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="11"
                                  fill="#000">
                                <path fill="#fff" d="M0 0h8v11H0z"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M6 1H2a1 1 0 00-1 1v7a1 1 0 001 1h4a1 1 0 001-1V2a1 1 0 00-1-1zM4 7a1 1 0 110 2 1 1 0 010-2z"/>
                            </mask>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M6 1H2a1 1 0 00-1 1v7a1 1 0 001 1h4a1 1 0 001-1V2a1 1 0 00-1-1zM4 7a1 1 0 110 2 1 1 0 010-2z"
                                  fill="#fff"/>
                            <path
                                d="M6 0H2v2h4V0zM2 0a2 2 0 00-2 2h2V0zM0 2v7h2V2H0zm0 7a2 2 0 002 2V9H0zm2 2h4V9H2v2zm4 0a2 2 0 002-2H6v2zm2-2V2H6v7h2zm0-7a2 2 0 00-2-2v2h2zM4 8h2a2 2 0 00-2-2v2zm0 0v2a2 2 0 002-2H4zm0 0H2a2 2 0 002 2V8zm0 0V6a2 2 0 00-2 2h2z"
                                fill="#8F9797" mask="url(#a)"/>
                        </svg>
                        sent
                        <svg width="9" height="7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1.54 3.207a.75.75 0 00-1.08 1.04l1.08-1.04zM3.187 6l-.54.52a.75.75 0 001.08 0L3.188 6zM8.54 1.52A.75.75 0 107.46.48l1.08 1.04zM.46 4.247L2.647 6.52l1.08-1.04L1.54 3.207.46 4.247zM3.728 6.52l4.812-5L7.46.48l-4.813 5 1.08 1.04z"
                                fill={iconColor}/>
                        </svg>
                        delivered
                    </div>
                </div>
                {showReply && <div className="ta-widget-chat-form-received ta-animate ta-fade-in">
                    <div className="ta-widget-chat-form-received-phone">
                        {props.config.partnerPhone}
                    </div>
                    <div className="ta-widget-chat-form-received-body">
                        <div className="ta-widget-chat-form-received-title">
                            We received your message!
                        </div>
                        <div>
                            Our team will be texting you back from number above.
                        </div>
                        <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 16c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z" fill="#00CC7B"/>
                            <path d="M5 8l2 2 4-4" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>}
            </>
            }

            {props.visible &&
            <>
                <div className="ta-widget-chat-form-about">
                    By submitting you agree to receive text messages at the number provided. Message/data
                    rates apply.
                </div>
                <button type="button"
                        className="ta-widget-chat-form-send"
                        tabIndex="4"
                        style={submitButtonStyles}
                        onClick={sendMessage}
                        disabled={!validForm}
                >
                    Send
                </button>
            </>
            }

        </>
    )

}

Form.propTypes = {
    uuid: PropTypes.string.isRequired,
    primaryColor: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
}

const mapStateToProps = (state) => {
    return {
        chat: state.chat,
        form: state.chat.form,
        helloMessage: state.helloMessage,
        config: state.config
    }
}

const mapDispatchToProps = {
    setForm: actions.setForm
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);