import React from "react";
import {connect} from "react-redux";
import PropTypes from 'prop-types';


const Icon = props => {
    // if checkbox is open
    if(props.chat.visible){
        return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.43412 2.53025C-0.144707 1.95142 -0.144707 1.01296 0.43412 0.434137C1.01295 -0.144689 1.95141 -0.144689 2.53024 0.434136L15.5659 13.4698C16.1447 14.0486 16.1447 14.9871 15.5659 15.5659C14.9871 16.1447 14.0486 16.1447 13.4698 15.5659L0.43412 2.53025Z" fill={props.config.actionButtons.webChat.iconColor}/>
            <path d="M13.4698 0.434119C14.0486 -0.144706 14.9871 -0.144706 15.5659 0.434119C16.1447 1.01295 16.1447 1.95141 15.5659 2.53023L2.53022 15.5659C1.95139 16.1447 1.01293 16.1447 0.434103 15.5659C-0.144725 14.987 -0.144725 14.0486 0.434103 13.4697L13.4698 0.434119Z" fill={props.config.actionButtons.webChat.iconColor}/>
        </svg>);
    }

    return (() => {
        switch (props.config.icon) {
            case 1:
                return (
                    <svg width="32" height="28" xmlns="http://www.w3.org/2000/svg" fill={props.config.actionButtons.webChat.iconColor} viewBox="0 0 512 512" >
                        <path d="m462.75 210.48c-3.979-1.656-8.583-0.74-11.625 2.313l-65.854 65.833c-4.479 4.49-10.146 7.51-16.333 8.75l-53.333 10.667c-10.625 2.146-21.375-1.229-28.896-8.74-7.583-7.594-10.854-18.406-8.75-28.906l9.408-47.063h-170.03c-5.896 0-10.667-4.771-10.667-10.667s4.771-10.667 10.667-10.667h179.06c0.371-0.411 0.587-0.928 0.98-1.323l41.35-41.344h-221.39c-5.896 0-10.667-4.771-10.667-10.667s4.771-10.667 10.667-10.667h234.67c2.271 0 4.259 0.866 5.988 2.074l69.22-69.21c5.833-5.844 12.896-10.438 21-13.667 3.688-1.469 6.25-4.875 6.646-8.823 0.417-3.958-1.417-7.802-4.729-9.99-7.021-4.615-15.125-7.052-23.458-7.052h-384c-23.521 3e-3 -42.667 19.139-42.667 42.67v416c0 4.313 2.604 8.208 6.583 9.854 1.313 0.552 2.708 0.813 4.083 0.813 2.771 0 5.5-1.083 7.542-3.125l103.54-103.54h304.92c23.521 0 42.667-19.135 42.667-42.667v-121c-1e-3 -4.312-2.605-8.208-6.584-9.854zm-217.42 66.854h-128c-5.896 0-10.667-4.771-10.667-10.667s4.771-10.667 10.667-10.667h128c5.896 0 10.667 4.771 10.667 10.667 0 5.897-4.771 10.667-10.667 10.667z"/>
                        <path d="m471.17 64c-10.771 0-21.292 4.365-28.875 11.958l-129.83 129.81c-1.5 1.49-2.5 3.385-2.917 5.448l-10.667 53.354c-0.708 3.5 0.396 7.115 2.917 9.635 2.021 2.021 4.75 3.125 7.542 3.125 0.688 0 1.396-0.073 2.083-0.208l53.313-10.667c2.083-0.417 3.979-1.427 5.458-2.917l129.85-129.83v-0.01c7.604-7.604 11.958-18.125 11.958-28.865 1e-3 -22.52-18.311-40.833-40.832-40.833zm-16.918 85.332l-27.587-27.587 15.083-15.083 27.589 27.589-15.085 15.081z"/>
                    </svg>
                )
            case 2:
                return (
                    <svg width="32" height="28" xmlns="http://www.w3.org/2000/svg" fill={props.config.actionButtons.webChat.iconColor} viewBox="0 0 465.882 465.882" >
                        <path d="m436.76 0h-291.18c-16.082 0-29.118 13.036-29.118 29.118v58.235h203.82c32.118 0 58.235 26.118 58.235 58.235v169.85l58.235 33.971-19.412-87.353h19.412c16.082 0 29.118-13.038 29.118-29.118v-203.82c0-16.082-13.035-29.118-29.117-29.118z"/>
                        <path d="m320.29 116.47h-291.18c-16.082 0-29.118 13.035-29.118 29.117v203.82c0 16.08 13.036 29.118 29.118 29.118h77.648l-19.412 87.353 149.75-87.353h83.193c16.082 0 29.118-13.038 29.118-29.118v-203.82c-2e-3 -16.082-13.038-29.117-29.12-29.117zm-218.38 174.7c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559 8.042 0 14.559 6.517 14.559 14.559 0 8.041-6.517 14.559-14.559 14.559zm72.794 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559 8.042 0 14.559 6.517 14.559 14.559 0 8.041-6.517 14.559-14.559 14.559zm72.794 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559 8.042 0 14.559 6.517 14.559 14.559 0 8.041-6.517 14.559-14.559 14.559z"/>
                    </svg>
                )
            case 3:
                return (
                    <svg width="32" height="28" xmlns="http://www.w3.org/2000/svg" fill={props.config.actionButtons.webChat.iconColor} viewBox="0 0 465.884 465.884" >
                        <path d="m449.5 225.34c10.523-19.914 16.388-41.972 16.388-65.196 0-88.448-84.737-160.15-189.26-160.15-95.457 0-174.19 59.856-187.17 137.6 20.847-9.334 43.632-15.746 67.69-18.842 20.464-35.578 66.213-60.519 119.48-60.519 72.254 0 131.03 45.724 131.03 101.91 0 13.109-3.242 25.89-9.64 37.975-4.841 9.169-8.28 18.733-10.651 28.439 12.892 24.125 20.29 50.887 20.29 79.174 0 4.907-0.387 9.739-0.818 14.559h59.053l-19.011-42.774c-7.478-16.828-5.978-35.896 2.625-52.177z"/>
                        <path d="m189.26 145.59c-104.53 0-189.26 71.699-189.26 160.15 0 23.224 5.865 45.283 16.388 65.196 8.603 16.281 10.103 35.349 2.623 52.177l-19.011 42.774h203.82l-1.242-0.569c98.288-5.804 175.95-74.936 175.95-159.58-1e-3 -88.448-84.738-160.15-189.26-160.15zm-87.353 203.82c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm87.353 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm87.353 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c-1e-3 8.04-6.519 14.559-14.559 14.559z"/>
                    </svg>
                )
            case 4:
                return (
                    <svg width="32" height="28" xmlns="http://www.w3.org/2000/svg" fill={props.config.actionButtons.webChat.iconColor} viewBox="0 0 465.884 465.884" >
                        <path d="m232.94 0c-128.65 0-232.94 91.254-232.94 203.82 0 27.129 6.06 53.017 17.059 76.69 26.898 57.892 23.683 125.86-12.218 178.65-3.01 4.426-4.841 6.722-4.841 6.722s117.19-21.41 178.37-64.091c17.534 3.689 35.743 5.856 54.571 5.856 128.65 0 232.94-91.256 232.94-203.82 0-112.57-104.29-203.82-232.94-203.82zm-101.91 232.94c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm101.91 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559zm101.91 0c-8.04 0-14.559-6.519-14.559-14.559 0-8.042 6.519-14.559 14.559-14.559s14.559 6.517 14.559 14.559c0 8.04-6.519 14.559-14.559 14.559z"/>
                    </svg>
                )
            case 5:
                return (
                    <svg xmlns="http://www.w3.org/2000/svg" id="Capa_1" x="0px" y="0px" width="32" height="28" viewBox="0 0 512 512" fill={props.config.actionButtons.webChat.iconColor} >
                        <circle cx="254.7" cy="257.3" r="254.7"/>
                        <circle className="st0" fill="none" stroke="#FFFFFF" strokeWidth={20} strokeMiterlimit={10} cx="256" cy="256" r="213"/>
                        <path fill="#FFFFFF"
                              d="M300.1,221.3c24.4,24.4,24.4,63.8,0,88.2c-24.4,24.4-63.8,24.4-88.2,0s-24.4-63.8,0-88.2  S275.7,196.9,300.1,221.3"/>
                        <path fill="#FFFFFF"
                              d="M368.3,162.4h-25.9L323.7,125H188.3l-18.7,37.4l-25.8,0c-20.6,0-37.3,16.8-37.4,37.4l-0.1,149.7  c0,20.6,16.8,37.4,37.4,37.4h224.6c20.6,0,37.4-16.8,37.4-37.4V199.9C405.7,179.2,388.9,162.4,368.3,162.4z M256,340.2  c-41.3,0-74.9-33.6-74.9-74.9s33.6-74.9,74.9-74.9s74.9,33.6,74.9,74.9S297.3,340.2,256,340.2L256,340.2z"/>
                    </svg>
                )
            default:
                return (
                    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg" >
                        <path d="M21.9985 16.4357C21.2108 17.2126 20.2767 17.8238 19.2509 18.2333C18.225 18.6429 17.1282 18.8426 16.0245 18.8208C15.4101 18.8208 14.7926 18.8208 14.1782 18.8208C14.0765 18.8142 13.9746 18.831 13.8803 18.87C13.786 18.909 13.7019 18.9691 13.6343 19.0458C12.2488 20.5116 10.8505 21.9677 9.47455 23.4271C9.34599 23.5794 9.18628 23.7022 9.00629 23.7871C8.8263 23.8719 8.63024 23.9169 8.43142 23.9189C8.26508 23.9185 8.1005 23.8847 7.94734 23.8195C7.79419 23.7543 7.65555 23.659 7.53959 23.5393C7.42362 23.4195 7.33266 23.2776 7.27206 23.122C7.21146 22.9664 7.18244 22.8001 7.18671 22.6331C7.18671 21.418 7.18671 20.2041 7.18671 18.9912C7.18671 18.7597 7.12271 18.6697 6.88913 18.6247C3.47816 17.744 1.3983 15.5099 0.387173 12.1958C0.225399 11.5698 0.0961664 10.9357 0 10.2961V8.53461C0.0616852 7.97392 0.168698 7.41921 0.319978 6.87597C1.15512 3.82869 2.9534 1.62037 5.94839 0.472823C6.80932 0.152208 7.72126 -0.00792136 8.63941 0.000301326C11.0488 0.000301326 13.4615 0.000301326 15.8741 0.000301326C19.2403 -0.0157708 21.7585 1.48858 23.4864 4.30764C24.6226 6.20562 25.0935 8.43119 24.8238 10.6293C24.5541 12.8275 23.5595 14.8716 21.9985 16.4357Z" fill={props.config.actionButtons.webChat.iconColor}/>
                        <path d="M29.6108 11.7041C28.7218 10.9008 27.6246 10.367 26.4462 10.1643C26.4462 10.2511 26.4238 10.3026 26.4174 10.3508C26.3107 11.9022 25.8743 13.4126 25.1375 14.7803C23.16 18.3804 20.1618 20.3477 16.0053 20.3991C15.5669 20.3991 15.1285 20.3991 14.6902 20.3991C14.3318 20.3991 14.1718 20.6755 14.3542 20.9745C15.3653 22.6331 16.7796 23.7678 18.7155 24.1053C19.5529 24.2085 20.3967 24.2504 21.2401 24.2307C21.3371 24.2247 21.4342 24.241 21.524 24.2783C21.6139 24.3156 21.694 24.373 21.7585 24.446C22.3217 25.0504 22.8976 25.6418 23.4672 26.2397C23.9055 26.6994 24.3407 27.1687 24.7919 27.6219C24.9827 27.8418 25.2525 27.9768 25.5422 27.9973C25.8318 28.0178 26.1178 27.9221 26.3374 27.7312C26.4698 27.6102 26.573 27.4605 26.6389 27.2933C26.7049 27.1262 26.732 26.9461 26.7182 26.7669C26.7182 26.005 26.7182 25.24 26.7182 24.4782C26.7182 24.205 26.7917 24.1085 27.0605 24.0057C27.724 23.793 28.36 23.5017 28.9548 23.1378C32.7241 20.5341 33.0537 14.777 29.6108 11.7041Z" fill={props.config.actionButtons.webChat.iconColor}/>
                    </svg>
                )
        }
    })()

}

const mapStateToProps = (state) => {
    return {
        chat: state.chat,
        config: state.config
    }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Icon)