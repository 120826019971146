import { combineReducers} from "redux";
import types from "./types";

const initChatState = {
    visible: false,
    form: {
        name: null,
        phone: null,
        phoneCode: 1,
        phoneIso2: 'us',
        message: ''
    }
}

const chatReducer = (state = initChatState, action) => {
    switch (action.type) {
        case types.CHAT_VISIBLE:
            if (action.visible) {
                return {...state, visible: true}
            } else {
                return {...state, visible: false}
            }
        case types.SET_FORM:
            return {...state, form: action.form}
        default:
            return state
    }
}


const initHelloMessageState = {
    visible: false
}

const helloMessageReducer = (state = initHelloMessageState, action) => {
    switch (action.type) {
        case types.HELLO_MESSAGE_VISIBLE:
            if (action.visible) {
                return {...state, visible: true}
            } else {
                return {...state, visible: false}
            }
        default:
            return state
    }
}

const initConfigState = {
    style: 'drawer',
    textColor: '',
    title: 'How can we help you!',
    bubbleLogo: false,
    partnerName: '',
    partner: '',
    primaryColor: '',
    partnerPhone: '',
    initialMessage: '',
    icon: 1,
    actionButtons: {
        webChat: {
            iconColor: 'white'
        },
        appointment: {
            enabled: 0
        }
    },
    error: false,
}

const configReducer = (state = initConfigState, action) => {
    switch (action.type) {
        case types.SET_CONFIG:
            return { ...state, ...action.config };
        default:
            return state
    }
}


const reducers = combineReducers({
    chat: chatReducer,
    helloMessage: helloMessageReducer,
    config: configReducer
})


export default reducers;