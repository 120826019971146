import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


export const init = uuid => {

    return ReactDOM.render(
        <React.StrictMode>
            <App uuid={uuid}/>
        </React.StrictMode>,
        document.getElementById('trustanalytica-chat')
    )
};


if (process.env.REACT_APP_ENV === 'dev') {
    init('acbf1353-ff9e-4184-91fe-de12af77bfbf');
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
