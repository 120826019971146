import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import actions from "../redux/actions";
import "./ChatButton.scss";
import Bubble from "./Bubble"
import Icon from "./Icon"

const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
};

const ChatButton = props => {
    const [originalPosition, setOriginalPosition] = useState({bottom: 0, right: 0})
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [smallDevice, setSmallDevice] = useState(false);

    useEffect(() => {
        props.visibleMessage();

        // setTimeout(() => {
        //     props.hideMessage();
        // }, 5000);

    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        setSmallDevice(windowDimensions.width < 415 || windowDimensions.height < 415);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const el = document.getElementById('trustanalytica-chat');
        setSmallDevice(windowDimensions.width < 415 || windowDimensions.height < 415);

        if (props.chat.visible && (windowDimensions.width < 415 || windowDimensions.height < 415) && el.style.bottom !== '0px') {

            setOriginalPosition({
                bottom:  el.style.bottom,
                right: el.style.right
            });
            el.style.bottom = '0px';
            el.style.right = '0px';
        } else if (!props.chat.visible && el.style.bottom === '0px') {
            el.style.bottom = originalPosition.bottom;
            el.style.right = originalPosition.right;
        }


    }, [windowDimensions, props.chat.visible]);

    const toggleVisibleChat = () => {
       if (props.chat.visible) {
           props.hideChat();
       } else {
           props.visibleChat();
           props.hideMessage();
       }
    };

    if (!props.config.isDisabled) {
        let style = {};
        if (typeof props.config !== "undefined") {
            style = {
                backgroundColor: props.config.primaryColor
            }
        }
        return (
            <div
                className={`ta-animate-fast ta-fade-in ta-chat-init-button ${!props.helloMessage.visible ? 'ta-welcome-hidden' : ''} ${smallDevice && props.chat.visible ? 'ta-small-device' : ''}`}>
                <Bubble />
                <div className={`ta-chat-button ${props.chat.visible ? 'close ta-rotate-in' : 'ta-rotate-out'}`}
                        onClick={toggleVisibleChat} style={style}>
                    <Icon />
                </div>
            </div>
        )
    } else {
        return null;
    }
};

const mapStateToProps = (state) => {
    return {
        chat: state.chat,
        helloMessage: state.helloMessage,
        config: state.config
    }
}

const mapDispatchToProps = {
    visibleChat: actions.visibleChat,
    hideChat: actions.hideChat,
    hideMessage: actions.hideMessage,
    visibleMessage: actions.visibleMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatButton)