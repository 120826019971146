import React, {useEffect} from 'react';
import {Provider} from 'react-redux';
import store from "./redux";
import Chat from "./screens/Chat";
import './App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const App = props => {
    return (
        <Provider store={store}>
            <Chat uuid={props.uuid}/>
            <ToastContainer />
        </Provider>
    )
}



export default App;