import axios from 'axios'
axios.defaults.baseURL = process.env.REACT_APP_SOCKET_API_BASE;

export const getConfig = uuid => {
    const config = {
        style: 'drawer',
        textColor: '',
        logo: '',
        partnerName: '',
        partner: '',
        primaryColor: '',
        partnerPhone: '',
        initialMessage: '',
        icon: 1
    };

    return axios.get(`${process.env.REACT_APP_API_BASE}/chat/config`, {
        params: {
            uuid: uuid
        }
    }).then(response => {
        return {
            ...config,
            icon: parseInt(response.data.icon),
            primaryColor: response.data.bannerBgColor,
            textColor: response.data.bannerTextColor,
            showBubble: parseInt(response.data.bubbleEnabled),
            bubbleSound: parseInt(response.data.bubbleUseSound),
            initialMessage: response.data.bubbleText,
            isDisabled: parseInt(response.data.websiteIntegrationEnabled) === 0,
            partner: uuid,
            partnerName: response.data.partnerName,
            partnerPhone: response.data.partnerPhone,
            title: response.data.title,
            bubbleLogo: response.data.bubbleLogo,
            actionButtons: response.data.actionButtons
        }
    }).catch(error => {
        return { error: error.response.data?.error };
    });
}